import React, { useEffect, useState } from "react";

interface QueueHomeProps {}

const QueueHome: React.FC<QueueHomeProps> = ({}) => {
	useEffect(() => {
		setTimeout(() => {
			window.location.href = "https://www.brushfire.com";
		}, 2000);
	}, []);

	return (
		<div style={{ textAlign: "center" }}>
			<div className="absolute-center">
				<svg className="flame-logo" viewBox="255.513 156.234 54.455 83.567" width="54.455" height="83.567">
					<path d="M 264.693 165.221 C 268.693 168.791 272.913 174.871 276.433 184.221 C 276.483 184.367 276.483 184.526 276.433 184.671 C 275.433 189.331 274.433 193.931 273.263 197.881 C 269.803 210.161 264.883 215.271 264.883 222.501 C 264.893 225.291 265.579 228.036 266.883 230.501 C 266.935 230.593 266.904 230.709 266.813 230.761 C 266.746 230.805 266.659 230.805 266.593 230.761 C 240.833 212.641 268.643 209.301 264.403 165.361 C 264.391 165.251 264.472 165.152 264.583 165.141 C 264.628 165.154 264.667 165.182 264.693 165.221 Z"></path>
					<path d="M 303.173 230.801 C 303.158 230.837 303.158 230.876 303.173 230.911 C 303.192 231.023 303.304 231.093 303.413 231.061 C 314.843 221.671 308.983 195.461 303.413 177.621 C 303.39 177.547 303.329 177.489 303.253 177.471 C 303.149 177.457 303.051 177.521 303.023 177.621 C 300.893 186.221 299.473 190.711 296.073 194.221 C 295.831 194.457 295.774 194.824 295.933 195.121 C 302.363 207.451 307.313 223.571 303.133 230.771 L 303.173 230.801 Z"></path>
					<path d="M 284.753 239.801 C 294.303 239.812 302.056 232.082 302.073 222.531 C 302.073 202.871 281.613 188.111 289.543 156.461 C 289.569 156.408 289.569 156.345 289.543 156.291 C 289.465 156.215 289.34 156.215 289.263 156.291 C 282.263 163.671 279.743 184.291 275.923 197.801 C 272.233 210.701 267.063 215.661 267.473 223.431 C 267.894 231.928 274.459 238.842 282.923 239.701 C 283.533 239.761 284.143 239.791 284.753 239.801 Z"></path>
				</svg>
			</div>
			<div className="absolute-center" style={{ zIndex: 5 }}>
				<div className="flame-circle"></div>
			</div>
		</div>
	);
};

export default QueueHome;

import React from "react";

export enum Status {
	off,
	waiting,
	on,
}

interface StatusButtonProps {
	status: Status;
	text: string;
	onClick: () => void;
}

const StatusButton: React.FC<StatusButtonProps> = ({ status, text, onClick }) => {
	return (
		<button className={`status-button`} onClick={onClick}>
			<div className={`status-button-dot ${Status[status]}`}></div>
			<div className="status-button-text">{text}</div>
		</button>
	);
};

export default StatusButton;

import React, { useEffect, useState } from "react";

interface FooterProps {}

const Footer: React.FC<FooterProps> = ({}) => {
	return (
		<footer className="footer-nav" id="brushfire-footer">
			<div className="footer-left">
				<div className="footer-logo-container">
					<img src="https://brushfire.com/img/bf-logo-full-light-320.png" alt="Brushfire flame logo" />
				</div>
				<div className="m-bottom-medium">
					<div className="privacy">
						<small className="privacy-text">
							© {new Date().getFullYear()}{" "}
							<a className="privacy-text m-0 m-left" href="https://brushfire.com" target="_blank">
								Brushfire
							</a>
						</small>
						<a className="privacy-text m-left" href="https://brushfire.com/legal" target="_blank">
							<small>Terms &amp; Privacy Policy</small>
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;

import "./App.css";
import "./Styles/.CSS/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, BrowserRouter, Routes as Switch, Navigate } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import EntryQueue from "./EntryQueue/EntryQueue";
import { Routes } from "./Constants";
import QueueHome from "./EntryQueue/QueueHome";

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Switch>
					<Route index path={Routes.dashboardLive} element={<Dashboard path={Routes.dashboardLive} />} />
					<Route path={Routes.dashboardClients} element={<Dashboard path={Routes.dashboardClients} />} />
					<Route path={Routes.dashboardSettings} element={<Dashboard path={Routes.dashboardSettings} />} />
					<Route path={Routes.dashboard} element={<Navigate to={Routes.dashboardLive} replace />} />
					<Route path="/" element={<QueueHome />} />
					<Route path="/*" element={<EntryQueue />} />
				</Switch>
			</BrowserRouter>
		</div>
	);
}

export default App;

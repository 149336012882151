import { EventSingleOutput } from "@brushfire/types";
import { Well } from "@brushfire/ui";
import React, { useState, useEffect } from "react";
import { doc, onSnapshot, getFirestore } from "firebase/firestore";
import fb from "../firebase";
import { GlobalSettings, PublicUserPresence, UserPresence } from "../../../Widget/src/interfaces/UserPresence";
import EventHeader from "./EventHeader";
import { FormControlLabel, FormGroup, styled, Switch } from "@mui/material";
import Helmet from "react-helmet";
const walker1 = "https://brushfirecontent.blob.core.windows.net/0000/Media/walk-1.png";
const walker2 = "https://brushfirecontent.blob.core.windows.net/0000/Media/walk-2.png";
const walker3 = "https://brushfirecontent.blob.core.windows.net/0000/Media/walk-3.png";
const walker4 = "https://brushfirecontent.blob.core.windows.net/0000/Media/walk-4.png";
const walker5 = "https://brushfirecontent.blob.core.windows.net/0000/Media/walk-5.png";
const walker6 = "https://brushfirecontent.blob.core.windows.net/0000/Media/walk-6.png";
const walker7 = "https://brushfirecontent.blob.core.windows.net/0000/Media/walk-7.png";

interface QueueViewProps {
	me?: PublicUserPresence | null;
	settings: GlobalSettings;
	eventNumber?: string;
	clientKey: string;
	urlKey?: string;
	playAudio: boolean;
	setPlayAudio: (playAudio: boolean) => void;
	accessAllowed: boolean;
	showLink: boolean;
	link: string;
}

interface Theme {
	LogoUrl: string;
	LogoPosition: string;
	HeaderUrlLarge: string | null;
	HeaderUrlMobile: string | null;
	BackgroundCss: string;
	TextColor: string;
	LinkColor: string;
	HomeUrl: string | null;
	HeaderHtml: string | null;
	ClientKey: string;
	ClientName: string;
	UrlKey: string | null;
}

function getNumberWithOrdinal(n: number) {
	const s = ["th", "st", "nd", "rd"],
		v = n % 100;
	return (
		<span>
			{n}
			<sup>{s[(v - 20) % 10] || s[v] || s[0]}</sup>
		</span>
	);
}
const brandBlue = "#004bed";
const brandBlueDk = "#0033a1";
const BlueSwitch = styled(Switch)(({ theme }) => ({
	"& .MuiSwitch-switchBase": {
		"&.Mui-checked": {
			"& + .MuiSwitch-track": {
				backgroundColor: brandBlueDk,
			},
			"& .MuiSwitch-thumb": {
				backgroundColor: brandBlue,
			},
		},
	},
}));

const QueueView: React.FC<QueueViewProps> = ({
	me,
	settings,
	eventNumber,
	clientKey,
	urlKey,
	playAudio,
	setPlayAudio,
	accessAllowed,
	showLink,
	link,
}) => {
	const [event, setEvent] = useState<EventSingleOutput>();
	const [theme, setTheme] = useState<Theme>();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPlayAudio(event.target.checked);
	};

	const firestore = getFirestore(fb);

	const peopleRepetitions = 4;

	const images: React.ReactNode[] = [];

	const seconds = -48;
	const factor = 1;

	const urls: string[] = [
		walker1,
		walker2,
		walker3,
		walker4,
		walker5,
		walker2,
		walker6,
		walker7,
		//
		walker1,
		walker4,
		walker3,
		walker5,
		walker7,
		walker6,
		//
		walker4,
		walker2,
		walker1,
		walker5,
		walker6,
		walker3,
	];
	urls.forEach((url, index) => {
		images.push(
			<img
				src={url}
				alt="Person standing in line"
				style={{ animationDelay: (seconds / urls.length) * index + "s" }}
			/>
		);
	});

	useEffect(() => {
		if (eventNumber) {
			return onSnapshot(doc(firestore, "events", eventNumber), (doc) => {
				setEvent(doc.data() as EventSingleOutput);
			});
		}
	}, [eventNumber]);

	useEffect(() => {
		const parts = [clientKey];
		if (urlKey) {
			parts.push(urlKey);
			if (eventNumber) {
				parts.push(eventNumber);
			}
		}
		const docName = parts.join("@");
		return onSnapshot(doc(firestore, "themes", docName), (doc) => {
			const t = doc.data() as Theme;
			console.log(t);
			setTheme(t);
		});
	}, []);
	return (
		<>
			{theme && (
				<>
					<Helmet>
						<style type="text/css">
							{`.header-logo {
								max-width: 100%;
								margin: auto;
							}

							${
								!!theme.HeaderUrlLarge && !!theme.HeaderUrlMobile
									? `
									.area-banner-mobile {
										display: none;
										
									}
									@@media only screen and (max-width : 768px) {
										.area-banner {
											display: none;
										}
										.area-banner-mobile {
											display: block;
										}
									}
								`
									: ""
							}
							
							${
								!!theme.BackgroundCss
									? `
									html { background: none; ${theme.BackgroundCss} }
									body { background: none !important; }
								`
									: ""
							}
							${
								!!theme.HeaderUrlLarge || !!theme.HeaderUrlMobile || !!theme.LogoUrl
									? `.area-header {margin:0 auto;padding:20px 0 0 0;}`
									: ""
							}`}
						</style>
						<body className="set-inline"></body>
					</Helmet>
					<div id="brushfire-theme-header" className="area-header clearfix">
						{!!theme.HeaderHtml ? (
							<div dangerouslySetInnerHTML={{ __html: theme.HeaderHtml }}></div>
						) : (
							<>
								{!!theme.HeaderUrlLarge || !!theme.HeaderUrlMobile ? (
									<>
										<div className="area-banner">
											{!!theme.HomeUrl ? (
												<a href={theme.HomeUrl}>
													<img
														src={theme.HeaderUrlLarge ?? undefined}
														className="img-responsive"
														alt={`${
															event?.eventInfoTitle ? `${event?.eventInfoTitle} ` : ""
														}Large Header`}
													/>
												</a>
											) : (
												<img
													src={theme.HeaderUrlLarge ?? undefined}
													className="img-responsive"
													alt={`${
														event?.eventInfoTitle ? `${event?.eventInfoTitle} ` : ""
													}Large Header`}
												/>
											)}
										</div>
										<div className="area-banner-mobile">
											{!!theme.HomeUrl ? (
												<a href={theme.HomeUrl}>
													<img
														src={theme.HeaderUrlMobile ?? undefined}
														className="img-responsive"
														alt={`${
															event?.eventInfoTitle ? `${event?.eventInfoTitle} ` : ""
														}Mobile Header`}
													/>
												</a>
											) : (
												<img
													src={theme.HeaderUrlMobile ?? undefined}
													className="img-responsive"
													alt={`${
														event?.eventInfoTitle ? `${event?.eventInfoTitle} ` : ""
													}Mobile Header`}
												/>
											)}
										</div>
									</>
								) : (
									<>
										{!!theme.LogoUrl && (
											<div className={`header-logo text-${theme.LogoPosition}`}>
												{!!theme.HomeUrl ? (
													<a href={theme.HomeUrl}>
														<img
															src={theme.LogoUrl}
															className="img-responsive display-inline-block header-logo-image"
															alt={`${
																event?.eventInfoTitle ? `${event?.eventInfoTitle} ` : ""
															}Logo`}
														/>
													</a>
												) : (
													<img
														src={theme.LogoUrl}
														className="img-responsive display-inline-block header-logo-image"
														alt={`${
															event?.eventInfoTitle ? `${event?.eventInfoTitle} ` : ""
														}Logo`}
													/>
												)}
											</div>
										)}
									</>
								)}
							</>
						)}
					</div>
				</>
			)}
			{event ? (
				<div className="ta-left">
					<EventHeader
						event={event}
						currentTab={2}
						isLoading={!event}
						isWidget
						userCanManage={false}
						path={""}
					/>
				</div>
			) : (
				<>
					{!!eventNumber && (
						<div className="well lg ta-center d-block">
							<div className={`well-content`}>...</div>
						</div>
					)}
				</>
			)}

			<div className="well lg well-lg ta-center d-block border-radius-tablet-0">
				<div className={`well-content`}>
					{accessAllowed === true ? (
						<>
							<h2>Hold tight! Redirecting now...</h2>
							{showLink && <a href={link}>Click here to be redirected</a>}
						</>
					) : (
						<h2 className="bold">You're now in the queue!</h2>
					)}

					{settings.clients[clientKey]?.queueMessage && <p>{settings.clients[clientKey].queueMessage}</p>}

					<p>{settings.heading}</p>
					{!settings.paused && (
						<>
							{me?.p ? (
								<>
									<h3 className="bold">
										You are {me.allow === true ? "next" : getNumberWithOrdinal(me?.p ?? 1)} in line.{" "}
										{me?.e !== undefined
											? `Your estimated wait time is ${
													me.e < 3600
														? `less than ${Math.ceil(me.e / 60)} minute${
																Math.ceil(me.e / 60) === 1 ? "" : "s"
														  }`
														: `less than ${Math.ceil(me.e / 3600)} hour${
																Math.ceil(me.e / 3600) === 1 ? "" : "s"
														  }`
											  }.`
											: ""}
									</h3>
									{me?.o && me?.p && (
										<div className="queue-status">
											<div
												className="queue-status-indicator"
												style={{ width: `${100 - (me.p / me.o) * 100}%` }}
											></div>
										</div>
									)}
								</>
							) : (
								<>
									<h3 className="bold">Calculating your place in line</h3>
									<div className="queue-status">
										<div className="queue-status-indicator" style={{ width: `0%` }}></div>
									</div>
								</>
							)}
						</>
					)}

					<p>{settings.body}</p>

					<FormGroup>
						<FormControlLabel
							style={{ justifyContent: "center" }}
							control={
								<BlueSwitch
									checked={playAudio}
									onChange={handleChange}
									inputProps={{ "aria-label": "controlled" }}
								/>
							}
							label="Play a sound when it's my turn"
						/>
					</FormGroup>

					<div className={`walker-frame walkers-${peopleRepetitions} border-radius-tablet-0`}>{images}</div>
				</div>
			</div>
		</>
	);
};

export default QueueView;

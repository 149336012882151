import Well from "@brushfire/ui/dist/Layout/Well";
import React, { useState } from "react";
import { EventAccess, EventSingleOutput } from "@brushfire/types";
import { Alert, Icon } from "@brushfire/ui";
import { Dropdown, DropdownButton } from "react-bootstrap";

enum Tab {
	DETAILS,
	REGISTER,
	DASHBOARD,
}

interface HeaderProps {
	event: EventSingleOutput;
	currentTab: Tab;
	isLoading: boolean;
	isWidget: boolean;
	userCanManage: boolean;
	path: string;
	isQueue?: boolean;
	push?: (url: string, state?: unknown, refresh?: boolean) => void;
	search?: string;
}
const constants = {
	addressNone: "No address",
	datesNone: "Ongoing event/no specific date",
};

const Header: React.FC<HeaderProps> = ({
	event,
	currentTab,
	isLoading,
	isWidget,
	userCanManage,
	path,
	isQueue = false,
	push,
	search,
}) => {
	const [returnPath, setReturnPath] = useState<string>();

	const addressLink =
		event?.latitude && event?.longitude
			? `${event?.locationName ? `${encodeURIComponent(event?.locationName)}/@` : null}${event?.latitude},${
					event?.longitude
			  },17z`
			: event?.address !== constants?.addressNone
			? event?.address ?? null
			: null;

	if (!event) {
		return null;
	}

	return (
		<div className="well lg well-lg  ta-center d-block border-radius-tablet-0">
			<div className={`well-content`}>
				{userCanManage && (
					<a
						href={`https://${event.clientKey}.${process.env.REACT_APP_DOMAIN}/${event.urlKey}/${event.eventNumber}/manage`}
						target="_blank"
						rel="noopener noreferrer"
						className="manage-link"
					>
						<Icon icon="wrench" type="solid" classes="fa-flip-horizontal" />
					</a>
				)}

				<div className={`header-info-container ${userCanManage ? "has-wrench" : ""}`}>
					{event?.title && (
						<>
							{event.otherDates.length > 1 ? (
								<DropdownButton
									className="m-right-sm border-0 d-inline-block p-0 no-arrow ta-left"
									variant={"plain"}
									title={
										<>
											<h2 className={"bold ta-left"}>
												{event?.title} <Icon icon="chevron-down fa-2xs" />
											</h2>
										</>
									}
								>
									{event.otherDates?.map((otherDate) => {
										return (
											<Dropdown.Item
												disabled={otherDate.eventNumber === event.eventNumber}
												key={otherDate.eventNumber}
												onClick={(evt: React.MouseEvent) => {
													evt.stopPropagation();
													evt.preventDefault();

													window.location.href = window.location.href.replace(
														event.eventNumber.toString(),
														otherDate.eventNumber.toString()
													);
												}}
											>
												{otherDate.dateInfo}
											</Dropdown.Item>
										);
									})}
								</DropdownButton>
							) : (
								<h2 className={"bold"}>{event?.title}</h2>
							)}
						</>
					)}
					{event?.subtitle && <h4 className={"tt-uppercase"}>{event?.subtitle}</h4>}
					{event?.dateInfo !== constants?.datesNone &&
						event.dateInfoList &&
						event.dateInfoList.length &&
						event.dateInfoList.map((date, index) => {
							return <h6 key={index}>{date}</h6>;
						})}
					{addressLink !== null && event?.address !== constants?.addressNone ? (
						<h6>
							<a
								href={`https://www.google.com/maps/search/` + addressLink}
								target="_blank"
								rel="noopener noreferrer"
								className={"header-hyperlink-black"}
							>
								{event?.locationName}
							</a>
						</h6>
					) : (
						<h6>{event?.locationName}</h6>
					)}
				</div>

				{event?.accessState !== EventAccess.FullStop && (
					<>
						{!!event?.stickyInfo && (
							<div
								className={`alert alert-info ${
									isLoading ? "d-none" : ""
								} push-down sticky-header border-radius-tablet-0`}
							>
								<div
									className="dangerous-sticky wysiwyg-plain-text wysiwyg-text"
									dangerouslySetInnerHTML={{ __html: event?.stickyInfo }}
								></div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default Header;
